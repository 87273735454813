<template>
  <iq-card>
    <template v-slot:body>
      <div class="d-flex d-flex align-items-center justify-content-between mb-1">
        <div>
          <h2>{{ data.result }}</h2>
          <p class="fontsize-sm m-0">
            {{ data.title }}
          </p>
          <button
            class="btn btn-link pl-0 mt-2"
            @click="viewDetails"
          >
            {{ displayText }}
          </button>
        </div>
        <div :class="data.iconClass">
          <i :class="data.icon" />
        </div>
      </div>
      <div v-if="displayVoucherPerHour">
        <ModalPerHour :data="perHourParams" />
      </div>
      <div v-if="displayVoucherCount">
        <ModalPerVoucher :data="perHourParams" />
      </div>
    </template>
  </iq-card>
</template>
<script>
import ModalPerHour from './ModalVoucherPerHour.vue'
import ModalPerVoucher from './ModalPerVoucher.vue'
export default {
  name: 'DashboardDetails',

  middleware: ['auth'],
  components: {
    ModalPerHour,
    ModalPerVoucher
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      perHourParams: this.data
    }
  },

  computed: {
    displayVoucherPerHour () {
      return this?.data?.from === 'voucher_perhour'
    },
    displayVoucherCount () {
      return this?.data?.from === 'voucher_count'
    },
    displayText () {
      return !this?.data.checked ? 'View breakdown' : 'View breakdown previous year'
    }
  },
  mounted () {
  },
  methods: {
    viewDetails () {
      if (this.data?.from === 'voucher_perhour') {
        this.$bvModal.show('modal-view-per-voucher-hour')
      }
      if (this.data?.from === 'voucher_count') {
        this.$bvModal.show('modal-view-per-voucher')
      }
      this.perHourParams = this.data
    }
  }
}
</script>
