<template>
  <b-modal
    id="modal-view-per-voucher"
    scrollable
    no-close-on-backdrop
    title="Breakdown Per Month"
  >
    <table class="table responsive-table">
      <thead>
        <tr>
          <th class="w-50">
            Month
          </th>
          <th class="w-50">
            Number of Voucher
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="breakdown in data.breakdown"
          :key="breakdown.month"
        >
          <td>
            {{ breakdown.month }}
          </td>
          <td>
            {{ breakdown.total_voucher }}
          </td>
        </tr>
      </tbody>
    </table>

    <template #modal-footer="{ cancel }">
      <b-button
        variant="danger"
        @click="cancel()"
      >
        Close Window
      </b-button>
    </template>
  </b-modal>
</template>
<script>

export default {
  name: 'ModalPerVoucher',

  middleware: ['auth'],
  props: {
    data: {
      type: Object,
      required: true
    }
  }

}
</script>
