<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-row>
      <b-col
        cols="12"
        class="d-flex justify-content-end p-2"
      >
        <b-form-checkbox
          v-model="checked"
          name="check-button"
          switch
        >
          View Previous Year?
        </b-form-checkbox>

      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        sm="6"
      >
        <DashboardDetails :data="{...totalVoucherCount, checked}" />
      </b-col>
      <b-col
        cols="12"
        sm="6"
      >
        <DashboardDetails :data="{...totalVoucherPerHour, checked}" />
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { UserDashboardService } from '@/services'
import DashboardDetails from '@/components/dashboard/Dashboard.vue'
import moment from 'moment'

export default {
  name: 'UserDashboard',
  middleware: ['auth', 'user'],
  components: {
    DashboardDetails
  },
  metaInfo () {
    return {
      title: 'Dashboard'
    }
  },

  data () {
    return {
      checked: false,
      year: moment().format('YYYY'),
      totalVoucherCount: {
        result: 0,
        title: '',
        icon: 'ri-inbox-fill',
        iconClass: ' rounded-circle iq-card-icon dark-icon-light iq-bg-warning',
        breakdown: [],
        from: 'voucher_count'
      },
      totalVoucherPerHour: {
        result: 0,
        title: '',
        icon: 'ri-inbox-fill',
        iconClass: ' rounded-circle iq-card-icon dark-icon-light iq-bg-success',
        breakdown: [],
        from: 'voucher_perhour'
      }
    }
  },

  watch: {

    'checked' (value) {
      if (value) {
        this.year = this.year - 1
        this.totalVoucherCount = { ...this.totalVoucherCount, result: this.getTotalVoucherCount(this.data?.total_voucher) }
        this.totalVoucherPerHour = { ...this.totalVoucherPerHour, result: this.getTotalVoucherPerHour(this.data?.total_hours) }
      } else {
        this.year = this.year + 1
        this.totalVoucherCount = { ...this.totalVoucherCount, result: this.getTotalVoucherCount(this.data?.total_voucher) }
        this.totalVoucherPerHour = { ...this.totalVoucherPerHour, result: this.getTotalVoucherPerHour(this.data?.total_hours) }
      }
      this.totalVoucherCount.title = `Voucher Issued Year ${this.year} [Total Count]`
      this.totalVoucherPerHour.title = `Voucher Issued Year ${this.year} [Total Hours]`
      this.getBreakdown()
    }

  },

  mounted () {
    core.index()
    this.getDashboardDetails()
    this.totalVoucherCount.title = `Voucher Issued Year ${this.year} [Total Count]`
    this.totalVoucherPerHour.title = `Voucher Issued Year ${this.year} [Total Hours]`
  },

  methods: {
    async getDashboardDetails () {
      await UserDashboardService.get().then(({ data }) => {
        this.data = data
        this.totalVoucherCount = { ...this.totalVoucherCount, result: this.getTotalVoucherCount(this.data?.total_voucher), breakdown: this.filterDataByYear(this?.data?.breakdown_total_voucher) }
        this.totalVoucherPerHour = { ...this.totalVoucherPerHour, result: this.getTotalVoucherPerHour(this.data?.total_hours), breakdown: this.filterDataByYear(this?.data?.breakdown_total_hours) }
      })
    },

    getBreakdown () {
      this.totalVoucherCount = { ...this.totalVoucherCount, breakdown: this.filterDataByYear(this?.data?.breakdown_total_voucher) }
      this.totalVoucherPerHour = { ...this.totalVoucherPerHour, breakdown: this.filterDataByYear(this?.data?.breakdown_total_hours) }
    },

    filterDataByYear (data) {
      return data.filter(el => parseInt(el.year) === parseInt(this.year))
    },

    getTotalVoucherCount (data) {
      return data.filter(el => parseInt(el.year) === parseInt(this.year))?.[0]?.total_voucher || 0
    },

    getTotalVoucherPerHour (data) {
      return data.filter(el => parseInt(el.year) === parseInt(this.year))?.[0]?.total_hours || 0
    }

  }
}
</script>
